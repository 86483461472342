import React,{useState,useEffect} from 'react';
import Navbar from '../Navbar';
import {TextField, InputLabel, Select, MenuItem, FormControl} from '@mui/material';
import { NavLink,useParams,useNavigate } from 'react-router-dom'

const EditTiffin = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [disabled,setDisabled] = useState(false)
    
    const [order,setOrder] = useState({ date: '', meal: '', price: '', quantity: '' })
    
    useEffect(()=>{
        getOrder()
        // eslint-disable-next-line 
      },[])
    
    // Pre-Filled Data
    const getOrder = async () => {
        let result = await fetch(`https://api.askfsd.com/editOrder/${params.id}/${params.id2}`)
        result = await result.json()
        setOrder(result)
    }
    
    let name, value
    const handleInputs = (e) => {
        name = e.target.name
        value = e.target.value
        setOrder({ ...order, [name]: value })
    }
    
    const submit = async (e) => {
        try{
            setDisabled(true)
            const { date, meal, price, quantity } = order
            const total = price*quantity
            
            let result = await fetch(`https://api.askfsd.com/editOrder/${params.id}/${params.id2}`,{
                method:'put',
                body:JSON.stringify({ date, meal, price, quantity, total }),
                headers:{'Content-Type':'application/json'}
            })
            result = await result.json()

            if(result.message){
                alert(result.message)
                navigate(`/addPayment/${params.id}`)
            }
            else{
                setDisabled(false)
                alert(result.error)
            }
        }
        catch{
          setDisabled(false)
          alert("Error")
        }
    }



    return (
        <center>
            <Navbar />
            <NavLink className='link' to={`/addPayment/${params.id}`}><h2 className='text-dark mt-4'>Edit Order</h2></NavLink>

            <div className="col-10 col-md-6 col-lg-4 mt-4">
                <TextField label="Edit Date" variant="outlined" type="date" className="form-control" name="date" format="MM/DD/YYYY"
                value={order.date} onChange={handleInputs} />
            </div>

            <div className="col-10 col-md-6 col-lg-4 mt-4">
                <FormControl fullWidth>
                    <InputLabel >Edit Meal</InputLabel>
                    <Select label="Edit Meal" value={order.meal} name='meal' onChange={handleInputs}>
                        {/* <div style={{ height: '30vh' }}> */}
                            <h5 className='pt-3 px-2'>Tiffin</h5>
                                <MenuItem value='Breakfast'>Breakfast</MenuItem>
                                <MenuItem value='Lunch'>Lunch</MenuItem>
                                <MenuItem value='Dinner'>Dinner</MenuItem>
                            <h5 className='pt-3 px-2'>Gym</h5>
                                <MenuItem value='Large-Thal'>Large-Thal</MenuItem>
                                <MenuItem value='Medium-Thal'>Medium-Thal</MenuItem>
                                <MenuItem value='Small-Thal'>Small-Thal</MenuItem>
                                <MenuItem value='Below-99'>Below-99</MenuItem>
                            <h5 className='pt-3 px-2'>Others</h5>
                                <MenuItem value='Iftar'>Iftar</MenuItem>
                                <MenuItem value='Roti'>Roti</MenuItem>
                                <MenuItem value='Fine'>Fine</MenuItem>
                        {/* </div> */}
                    </Select>
                </FormControl>
            </div>

            <div className="col-10 col-md-6 col-lg-4 mt-4">
                <FormControl fullWidth>
                    <InputLabel>Edit Price</InputLabel>
                    <Select label="Edit Price" value={order.price} name='price' onChange={handleInputs} >
                        {/* <div style={{ height: '30vh' }}> */}
                            <MenuItem key={5} value={5}>5</MenuItem>
                            {[...Array(20).keys()].map((value) => (
                                <MenuItem key={value + 1} value={(value + 1) * 10}>
                                    {(value + 1) * 10}
                                </MenuItem>
                            ))}
                        {/* </div> */}
                    </Select>
                </FormControl>
            </div>

            <div className="col-10 col-md-6 col-lg-4 mt-4">
                <FormControl fullWidth>
                    <InputLabel>Edit Quantity</InputLabel>
                    <Select label="Edit Quantity" value={order.quantity} name='quantity' onChange={handleInputs} >
                        {/* <div style={{ height: '30vh' }}> */}
                            {[...Array(10).keys()].map((value) => (
                                <MenuItem key={value + 1} value={value + 1}>
                                    {value + 1}
                                </MenuItem>
                            ))}
                        {/* </div> */}
                    </Select>
                </FormControl>
            </div>

            {/* <div className="col-10 col-md-6 col-lg-4 mt-4">
                <TextField label="Edit Price" variant="outlined" type="number" className="form-control" autoComplete='off'  name="price"  
                value={order.price} onChange={handleInputs} />
            </div>

            <div className="col-10 col-md-6 col-lg-4 mt-4">
                <TextField label="Edit Quantity" variant="outlined" type="number" className="form-control" autoComplete='off' name="quantity"  
                value={order.quantity} onChange={handleInputs} />
            </div> */}

            {disabled?
                <button className='btn primary-bg text-light col-4 col-md-2 mt-4 p-2' disabled onClick={submit}>Submit</button>
                :<button className='btn primary-bg text-light col-4 col-md-2 mt-4 p-2' onClick={submit}>Submit</button>
            }

        </center>
    )
}

export default EditTiffin