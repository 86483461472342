import React, { useState, useEffect } from 'react'
import { TextField } from "@mui/material";
import * as MdIcons from 'react-icons/md';
import * as RiIcons from 'react-icons/ri';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import '../../App.css'; // Import the CSS for smooth transitions

const AddPayment = () => {
    const navigate = useNavigate()
    const params = useParams()

    const [delay,setDelay] = useState(true)
    const [disabled,setDisabled] = useState(false)
    const [history, setHistory] = useState(false)

    const [customer,setCustomer] = useState('')
    const [bill,setBill] = useState('')
    const [paid,setPaid] = useState('')
    const [balance,setBalance] = useState('')
    const [orders,setOrders] = useState('')
    const [transactions,setTransactions] = useState([])
    const [activeMonth,setActiveMonth] = useState('')

    useEffect(()=>{
        setTimeout(() => setDelay(false), 99999)
        getCustomer()
        // eslint-disable-next-line 
    },[])
    
    // Get Data
    const getCustomer = async () => {
        let result = await fetch('https://api.askfsd.com/customers');
        result = await result.json();
    
        if (result) {
            // Find customer
            let customer = result.find((i) => i._id === params.id);
    
            // Ensure the customer object is found
            setCustomer(customer.name);

            // Calculate bill, payments, balance, etc.
            let sum1 = 0;
            const orders = customer.orders || [];
            const bill = orders.map((i) => i.total);
            for (let i of bill) {
                sum1 = sum1 + i;
            }
            setBill(sum1);

            let sum2 = 0;
            let payments = customer.payments || [];
            let paid = payments.map((i) => i.amount);
            for (let i of paid) {
                sum2 = sum2 + i;
            }
            setPaid(sum2);

            setBalance(sum2 - sum1);

            let sum3 = 0;
            let quantity = orders.map((i) => i.quantity);
            for (let i of quantity) {
                sum3 = sum3 + i;
            }
            setOrders(sum3);

            let combinedTransactions = [
                ...orders.map((order) => ({ ...order, type: 'order' })),
                ...payments.map((payment) => ({ ...payment, type: 'payment' })),
            ].sort((a, b) => new Date(b.date) - new Date(a.date));
            setTransactions(combinedTransactions);

            if (combinedTransactions.length > 0) {
                const latestMonth = combinedTransactions[0].date.slice(0, 7);
                setActiveMonth(latestMonth);
            }
        }
    }
    
    // Group transactions by year and month
    const groupByMonth = (data) => {
        return data.reduce((acc, item) => {
            const monthYear = item.date.slice(0, 7); // Format: "YYYY-MM"
            if (!acc[monthYear]) acc[monthYear] = [];
            acc[monthYear].push(item);
            return acc;
        }, {});
    };

    const groupedTransactions = groupByMonth(transactions);

    // Delete Order
    const removeOrder = async (id) => {
        const comfirmBox = window.confirm("Are You Sure?")
        if(comfirmBox === true){
            let result = await fetch(`https://api.askfsd.com/deleteOrder/${params.id}/${id}`,{
                method:'delete'
            })
            result = await result.json()

            if(result.message){
                alert(result.message)
                getCustomer()
            }
            else{
                alert(result.error)
            }
        }
    }

    // Delete Payment
    const removePayment = async (id) => {
        const comfirmBox = window.confirm("Are You Sure?")
        if(comfirmBox === true){
            let result = await fetch(`https://api.askfsd.com/deletePayment/${params.id}/${id}`,{
                method:'delete'
            })
            result = await result.json()
            
            if(result.message){
                alert(result.message)
                getCustomer()
            }
            else{
                alert(result.error)
            }
        }
    }
    


    // Add Payment

    // Get Today's Date
    const d = new Date()
    let year = d.getFullYear()
    
    let month = d.getMonth()+1
    if(month<10){month = '0'+month}
    
    let day = (d.getDate())
    if(day<10){day = '0'+day}

    const date = year+'-'+month+'-'+day

    const [payment,setPayment] = useState({ date: date, amount: '' })
    
    let name, value
    const handleInputs = (e) => {
        name = e.target.name
        value = e.target.value
        setPayment({ ...payment, [name]: value })
    }

    const submit = async () => {
        try{
            setDisabled(true)
            const { date, amount } = payment
            
            let result = await fetch(`https://api.askfsd.com/addPayment/${params.id}`,{
                method:'post',
                body:JSON.stringify({ date, amount }),
                headers:{'Content-Type':'application/json'}
            })
            result = await result.json()
            
            if(result.message){
                alert(result.message)
                getCustomer()
                setDisabled(false)
            }
            else{
                setDisabled(false)
                alert(result.error)
            }
        }
        catch{
          setDisabled(false)
          alert("Error")
        }
    }

    const logout = () => {
        localStorage.clear()
        navigate('/')
    }

    return (
        <>
            <div className='float bg-light pb-1'>
                <div className='primary-bg navbar p-3'>
                    <NavLink to='/home' className='link text-light head'>The Saffron</NavLink>
                    <a href={`https://app.askfsd.com/#/payments/${params.id}`} target='blank' className="link text-light float-right head"><MdIcons.MdAddAPhoto/></a>
                    <NavLink to="/" className="link text-light float-right head" onClick={logout}><RiIcons.RiLogoutCircleLine/></NavLink>
                </div>
                <NavLink className='link' to={`/addOrder/${params.id}`}><h2 className='text-dark mt-4'>Add Payment for {customer}</h2></NavLink>
                <center>
                    {history?
                    <div className='col-11 pt-4 pb-3 mt-3 mb-2 rounded border shadow history' onClick={()=>setHistory(!history)}>
                        <h3 className='left'><span> Total&nbsp;Bill&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: </span><span className='text-danger'>₹{bill}/-</span></h3>
                        <h3 className='left'><span> Total&nbsp;Paid&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: </span><span className='text-success'>₹{paid}/-</span></h3>
                        <h3 className='left'><span> Total&nbsp;Orders&nbsp;: </span><span className='text-success'>{orders}</span></h3>
                    </div>:
                    <div className='col-11 p-4 mt-3 mb-2 rounded border shadow'>
                        <h3 className='left' onClick={()=>setHistory(!history)}><span> Balance&nbsp;: </span>
                        <span className={`${balance<0?'text-danger':'text-success'}`}>{balance<0?'- ₹':'₹'}{balance<0?-(balance):balance}/-</span></h3>
                        <div className='left row'>&nbsp;&nbsp;&nbsp;
                            <TextField label="Set Amount" variant="standard" type='number' className='col-6' autoComplete='off' name='amount' onChange={handleInputs} />
                            <div className='col-3 col-md-2 p-2 mt-1'>
                                {disabled?
                                    <button className='btn primary-bg text-light' onClick={submit}>Pay</button>
                                    :<button className='btn primary-bg text-light' onClick={submit}>Pay</button>
                                }
                            </div>
                        </div>
                    </div>}
                </center>
            </div>
            
            <div className="container mt-4">
                {
                    Object.entries(groupedTransactions).map(([monthYear, monthTransactions]) => {
                        const [year, month] = monthYear.split('-');
                        const monthName = new Date(year, month - 1).toLocaleString('default', { month: 'long' });

                        return (
                            <div key={monthYear} className="mb-3">
                                <button
                                    className="btn primary-bg text-light w-100 text-left"
                                    onClick={() => setActiveMonth(activeMonth === monthYear ? '' : monthYear)}
                                >
                                    <h5 className='pt-2 d-flex justify-content-between align-items-center'>
                                        <span>{monthName} {year} ({monthTransactions.length} transactions)</span>
                                        {activeMonth === monthYear ? (
                                            <RiIcons.RiArrowUpSLine />
                                        ) : (
                                            <RiIcons.RiArrowDownSLine />
                                        )}
                                    </h5>
                                </button>

                                <div className={`accordion-content ${activeMonth === monthYear ? 'open' : ''}`}>
                                    {monthTransactions.map((i, index) => (
                                        <div key={index} className={`mt-2 ${i.type === 'payment' ? 'left' : 'right'}`}>
                                            {i.type === 'payment' ? (
                                                <>
                                                    <NavLink to={'/editPayment/'+params.id+'/'+i._id}   className='btn btn-dark col-10 shadow'>
                                                        <div className='row mt-2 pt-1 pb-1 padding'>
                                                            <h4 className='col-6 left text-success mt-2'>₹{i.amount}/-</h4>
                                                            <div className='col-6 right'>
                                                                <h4>{i.date.split('-').reverse().join('-')}</h4>
                                                                <center className='text-warning paid'>Paid</center>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                    &nbsp;&nbsp;&nbsp;<MdIcons.MdDeleteForever className='delete text-danger' onClick={()=>{removePayment(i._id)}} />
                                                </>
                                            ) : (
                                                <>
                                                    <MdIcons.MdDeleteForever className='delete text-danger' onClick={()=>{removeOrder(i._id)}} />&nbsp;&nbsp;&nbsp;
                                                    <NavLink to={'/editOrder/'+params.id+'/'+i._id} className='btn btn-dark col-10 shadow'>
                                                        <div className='row mt-2 pt-1 pb-1 padding'>
                                                            <div className='col-6 left'>
                                                                <h4>{i.date.split('-').reverse().join('-')}</h4>
                                                                <center className='text-warning meal'>{i.meal}</center>
                                                            </div>
                                                            <h4 className='col-6 right text-danger mt-2'>₹{i.total}/-</h4>
                                                        </div>
                                                    </NavLink>&nbsp;
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    })
                }

                {delay && transactions.length === 0 && (
                    <h2 className='mt-5'>Loading <div className="spinner-border" role="status"></div></h2>
                )}
            </div>

        </>
    )
}

export default AddPayment
