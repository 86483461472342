import React, { useState, useEffect } from 'react'
import * as RiIcons from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import '../../App.css'; // Import the CSS for smooth transitions

const Payment = () => {
    const params = useParams()
    const [delay,setDelay] = useState(true)

    const [customer,setCustomer] = useState('')
    const [security,setSecurity] = useState('')
    const [balance,setBalance] = useState('')
    const [transactions,setTransactions] = useState([])
    const [activeMonth,setActiveMonth] = useState('')

    useEffect(()=>{
        setTimeout(() => setDelay(false), 99999)
        getCustomer()
        // eslint-disable-next-line
    },[])

    const getCustomer = async () => {
        let result = await fetch('https://api.askfsd.com/customers')
        result = await result.json()

        if(result){
            // Get Customer Name
            let customer = result.filter((i) => i._id === params.id)[0];
            setCustomer(customer.name);
            setSecurity(customer.security);

            let sum1 = customer.orders.reduce((acc, order) => acc + order.total, 0);
            let sum2 = customer.payments.reduce((acc, payment) => acc + payment.amount, 0);
            setBalance(sum2 - sum1);

            let combinedTransactions = [
                ...customer.orders.map((order) => ({ ...order, type: 'order' })),
                ...customer.payments.map((payment) => ({ ...payment, type: 'payment' })),
            ].sort((a, b) => new Date(b.date) - new Date(a.date));
            setTransactions(combinedTransactions);

            // Set the default active month to the latest month with transactions
            if (combinedTransactions.length > 0) {
                const latestMonth = combinedTransactions[0].date.slice(0, 7); // Get "YYYY-MM" of the latest transaction
                setActiveMonth(latestMonth);
            }
        }
    };

    // Group transactions by year and month
    const groupByMonth = (data) => {
        return data.reduce((acc, item) => {
            const monthYear = item.date.slice(0, 7); // Format: "YYYY-MM"
            if (!acc[monthYear]) acc[monthYear] = [];
            acc[monthYear].push(item);
            return acc;
        }, {});
    };

    const groupedTransactions = groupByMonth(transactions);

    return (
        <>
            <div className='float bg-light pb-1'>
                <div className='primary-bg navbar p-3'>
                    <div className='text-light head'>The Saffron</div>
                    {/* <div className="text-light float-right head"><RiIcons.RiLogoutCircleLine/></div> */}
                </div>
                <h2 className='text-dark mt-4'>Payment History of {customer}</h2>
                <center>
                    <div className='col-11 p-4 mt-3 mb-2 rounded border shadow'>
                        <h3 className='left'><span> Security&nbsp;: </span>
                            <span className='text-success'>₹{security}/-</span></h3>
                        <h3 className='left mt-4'><span> Balance&nbsp;: </span>
                            <span className={`${balance<0?'text-danger' : 'text-success'}`}>{balance < 0 ? '- ₹' : '₹'}{Math.abs(balance)}/-</span></h3>
                    </div>
                </center>
            </div>

            <div className="container mt-4">
                {
                    Object.entries(groupedTransactions).map(([monthYear, monthTransactions]) => {
                        const [year, month] = monthYear.split('-');
                        const monthName = new Date(year, month - 1).toLocaleString('default', { month: 'long' });

                        return (
                            <div key={monthYear} className="mb-3">
                                <button
                                    className="btn primary-bg text-light w-100 text-left"
                                    onClick={() => setActiveMonth(activeMonth === monthYear ? '' : monthYear)}
                                >
                                    <h5 className='pt-2 d-flex justify-content-between align-items-center'>
                                        <span>{monthName} {year} ({monthTransactions.length} transactions)</span>
                                        {activeMonth === monthYear ? (
                                            <RiIcons.RiArrowUpSLine />
                                        ) : (
                                            <RiIcons.RiArrowDownSLine />
                                        )}
                                    </h5>
                                </button>

                                <div className={`accordion-content ${activeMonth === monthYear ? 'open' : ''}`}>
                                    {monthTransactions.map((i, index) => (
                                        <div key={index} className={`mt-2 ${i.type === 'payment' ? 'left' : 'right'}`}>
                                            {i.type === 'payment' ? (
                                                <div className='btn btn-dark col-10 shadow'>
                                                    <div className='row mt-2 pt-1 pb-1 padding'>
                                                        <h4 className='col-6 left text-success mt-2'>₹{i.amount}/-</h4>
                                                        <div className='col-6 right'>
                                                            <h4>{i.date.split('-').reverse().join('-')}</h4>
                                                            <center className='text-warning paid'>Paid</center>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='btn btn-dark col-10 shadow'>
                                                    <div className='row mt-2 pt-1 pb-1 padding'>
                                                        <div className='col-6 left'>
                                                            <h4>{i.date.split('-').reverse().join('-')}</h4>
                                                            <center className='text-warning meal'>{i.meal}</center>
                                                        </div>
                                                        <h4 className='col-6 right text-danger mt-2'>₹{i.total}/-</h4>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    })
                }

                {delay && transactions.length === 0 && (
                    <h2 className='mt-5'>Loading <div className="spinner-border" role="status"></div></h2>
                )}
            </div>
        </>
    );
};

export default Payment;
